import { initializeApp } from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import { getMessaging, onMessage, getToken } from 'firebase/messaging';

import { getTemplatesJSON } from './functions.js';

const vapidKey = 'BDPG_nAmXAiSMdFro6ntcVsujM-rYH1rkkncZ3EnM2Ljq1dZO5xOosFGtMn6vNsTLpzZI1xLKD6czOPXJ1jCnwE';

var firebaseConfig = {
    apiKey: "AIzaSyCXfFTqGmOpsO75iS2RdToXkwWbVzz6UjY",
    authDomain: "iot-atsa.firebaseapp.com",
    databaseURL: "https://iot-atsa.firebaseio.com",
    projectId: "iot-atsa",
    storageBucket: "iot-atsa.appspot.com",
    messagingSenderId: "907355205064",
    appId: "1:907355205064:web:d09e0a4d5710a6512a24fd"
};
const appConfig = firebaseConfig;


/*firebase.initializeApp(firebaseConfig);
const messaging = firebase.messaging();
alert("messaging");*/
var languages = [];
var labelsLocale = [];
var lang = "en";
var sufix = "atstorm";

var lang = "en";
var sufix = "atstorm";

var MsgElem = document.getElementById("msg");
var TokenElem = document.getElementById("token");
var NotisElem = document.getElementById("notis");
var ErrElem = document.getElementById("err");

var messaging = null;
  window.onload = () => {
    $(document).ready(function(){
      if(window.localStorage.getItem("control_local"+sufix) == null){
        
          $.getJSON("js/config/control_local.json", function(json) {
              
              if(window.localStorage.getItem("CtlAppVersion" + sufix) == null){
                  window.localStorage.setItem("CtlAppVersion" + sufix, json.control.CtlAppVersion);
              }
              window.localStorage.setItem("CtlLoadData" + sufix, json.control.CtlLoadData);
              window.localStorage.setItem("control_local" + sufix,JSON.stringify(json)); // this will show the info it in firebug console
              window.localStorage.setItem("CtlUrlWSIoT" + sufix,json.control.CtlUrlWSIoT);
              window.localStorage.setItem("CtlUrlVersion" + sufix,json.control.CtlUrlVersion);

              $.getJSON("js/config/locale.json", function(json) {
                window.localStorage.setItem("locale" + sufix,JSON.stringify(json));
                getTemplatesJSON();
              });
          });
      }
      else{
           if(window.localStorage.getItem("CtlAppVersion" + sufix) == null){
              var obj = JSON.parse(window.localStorage.getItem("control_local"));
              window.localStorage.setItem("CtlAppVersion" + sufix, obj.control.CtlAppVersion);
              window.localStorage.setItem("CtlLoadData" + sufix, obj.control.CtlLoadData);
              window.localStorage.setItem("CtlUrlWSIoT" + sufix,obj.control.CtlUrlWSIoT);
              window.localStorage.setItem("CtlUrlWSIoT" + sufix,obj.control.CtlUrlVersion);
          }
          getTemplatesJSON();
      }
    });

    var app = initializeApp(firebaseConfig);
    messaging = getMessaging(app);
    //console.log(messaging);
    

    'use strict';
      if ('serviceWorker' in navigator) {
        console.log("serviceWorker");
        navigator.serviceWorker.register('./sw.js');
        navigator.serviceWorker.register('firebase-messaging-sw.js').then((registration) => {
          //messaging.useServiceWorker(registration);
          console.log('Service Worker registrado con éxito:', registration);
         // alert("Registering");
    	    //registerNotificactions(); 
          // Manejar mensajes cuando la aplicación está en primer plano
          onMessage(messaging, (payload) => {
            var userbbdd = window.localStorage.getItem("useratstorm");
            var user = JSON.parse(userbbdd);
            console.log("Recibido")
            console.log('Message received. ', payload);
            if(user != null){
              //alert( $("#iddispositivo").val());
              var iddispositivo = $("#iddispositivo").val();
              var dispositivo = {id : iddispositivo};
              //dispositivo = {id : 27};
              console.log(dispositivo);
              getDeviceHTML(dispositivo);
            }
          });	
        });
	    }
  }
  export {messaging};	

function registerNotificactions() {
    if ('safari' in window && 'pushNotification' in window.safari) {
      const permissionData = window.safari.pushNotification.permission('web.systems.at3w-connect');
        if (permissionData.permission === 'default') {
          //alert("Has Permission");
          // Request permission
          window.safari.pushNotification.requestPermission(
            'web.systems.at3w-connect', // your push ID
            'devpwafirebase.at3w-connect.systems', // your website domain
          {}, // data
          (permissionData) => {
            if (permissionData.permission === 'granted') {
                console.log('Safari Push Notifications permission granted');
                // Handle subscription
            } else if (permissionData.permission === 'denied') {
                console.error('Safari Push Notifications permission denied');
            }
          });
      } else if (permissionData.permission === 'granted') {
          console.log('Safari Push Notifications permission already granted');
        // Handle subscription
      }
    }
    else{
        if (!("Notification" in window)) {
            // Check if the browser supports notifications
            alert("This browser does not support desktop notification");
        } else if (Notification.permission === "granted") {
            // Check whether notification permissions have already been granted;
            // if so, create a notification

            Notification.requestPermission().then((permission) => {
                if (permission === 'granted') {
                    console.log(messaging);
                    getToken( messaging,{  vapidKey: 'BDPG_nAmXAiSMdFro6ntcVsujM-rYH1rkkncZ3EnM2Ljq1dZO5xOosFGtMn6vNsTLpzZI1xLKD6czOPXJ1jCnwE' }).then((token) => {
                        console.log('Token:', token);
                        window.localStorage.setItem('tokenfirebase',token);
                        //loginApp();
                        //registerToken(token);
                        // Tu lógica aquí para manejar el token
                        //TokenElem.innerHTML = "token is : " + token;
                    });
                }
            });
        // …
        } else if (Notification.permission !== "denied") {
        // We need to ask the user for permission
            //alert("Pidiendo permiso");
    
            Notification.requestPermission().then((permission) => {
                // If the user accepts, let's create a notification
                if (permission === "granted") {
                    //const notification = new Notification("Hi there!");
                    //getToken().then((token) => {
                    getToken( messaging, {vapidKey: 'BDPG_nAmXAiSMdFro6ntcVsujM-rYH1rkkncZ3EnM2Ljq1dZO5xOosFGtMn6vNsTLpzZI1xLKD6czOPXJ1jCnwE' }).then((token) => {
                    console.log(token);
                       // alert("Return token " + token);
                        window.localStorage.setItem('tokenfirebase',token);
                       // loginApp();
                        //registerToken(token);
                        //const notification = new Notification("Hi there!");
                       // TokenElem.innerHTML = "token is : " + token
                    });
                }
            });
        }
        // At last, if the user has denied notifications, and you
        // want to be respectful there is no need to bother them anymore.
    }
}

function getDeviceHTML(device){  
    $.ajax({
        url: "principal.html",
        crossDomain: true,
        cache: false,
        headers: {
            "cache-control": "no-cache"
        },
        success: function(data) {
            console.log(device);
            $("#containerAPP").html(data);
            showLoading();
            var userbbdd = window.localStorage.getItem("useratstorm");

            var user = JSON.parse(userbbdd);
            var id = device.id;
            var url = window.localStorage.getItem("CtlUrlWSIoT" + sufix) + "/getDispositivoWithAlarms/" + id + "/" + user.id;;
            // var url = "http://localhost:8000/WS_REST/getDispositivoWithAlarms/" + id + "/" + user.id; 

         //    alert(url);
            $.ajax({
                type: 'post',
                url: url,
                crossDomain: true,
                async:true,
                cache: false,
                headers: {
                    "cache-control": "no-cache"
                },
                cache: false,
                contentType: "application/json",
                success: function(data) {
                    console.log(data);
                    if(data != null){
                        $("#labeldevice").html(data.device.descripcion);
                        $("#textAlarm").html(getTextAlarm(data.device.ultimo_estado));
                        $("#circulo").css("border-color",getColorAlarm(data.device.ultimo_estado));
                        $("#fieldsetAlerta").css("border-color",getColorAlarm(data.ultimo_estado));

                        $("#token").val(window.localStorage.getItem("tokenfirebase"));
                        $("#iddispositivo").val(data.device.id);

                        if(data.alarms != 0){
                          $("#btnAceptarEvento").css("display","block");
                        }
                        //$("#iddispositivo").val(27);
                    }
                    else{
                        showError(labelsLocale["error_login"]);
                    }
                },
                error: function(jqXHR, textStatus, errorThrown) {
                    alert("error");
                    showError(labelsLocale["error_login"]);
                },
                complete: function(data, textStatus, errorThrown){
                    closeLoading();
                }
            });
        }
    });
}
export {getDeviceHTML};  

function getTextAlarm(status){
    var text = "Desconectado";
    switch(status){
        case 0:
            text = "NORMAL";
            $("#btnAceptarEvento").css("display","none");
        break;
        case 1:
            text = "ALERTA 1";
            $("#btnAceptarEvento").css("display","block");
        break;
         case 1:
            text = "ALERTA 2";
            $("#btnAceptarEvento").css("display","block");
        break;
         case 1:
            text = "MÁXIMO RIESGO";
            $("#btnAceptarEvento").css("display","block");
        break;
    }
    return text;
}

function getColorAlarm(status){
    var color = "#808080";
    switch(status){
        case 0:
            color = "#3ec222";
        break;
        case 1:
            color = "#ffdc00";
        break;
        case 2:
            color = "#ff8900";
        break;
        case 3:
            color = "#ff1a00";
        break;
    }
    return color;
}

function showError(label){
    if(label == null){
        label = labelsLocale["unexpected_error"];
    }

    $("#labelerror").html(label);
    $("#errordiv").css("display","block");
}
export {showError};  


export function closeError(){
  $("#errordiv").css("display","none");
}
//export {closeError};  

function showLoading(){
    $("#loading").css("display","block");
}
export {showLoading};  

function closeLoading(){
    $("#loading").css("display","none");
}
export {closeLoading};  

/*var notifyButton = document.getElementById("requestpermiso");
notifyButton.addEventListener('click', function() {
	notifyMe();
});


function notifyMe() {
  alert("Notifying 2");
  if ('safari' in window && 'pushNotification' in window.safari) {
      alert("SAFARI");
      const permissionData = window.safari.pushNotification.permission('web.systems.at3w-connect');
        if (permissionData.permission === 'default') {
          alert("Has Permission");
          // Request permission
          window.safari.pushNotification.requestPermission(
            'web.systems.at3w-connect', // your push ID
            'pwagoogle.at3w-connect.systems', // your website domain
          {}, // data
          (permissionData) => {
            if (permissionData.permission === 'granted') {
                console.log('Safari Push Notifications permission granted');
                // Handle subscription
            } else if (permissionData.permission === 'denied') {
                console.error('Safari Push Notifications permission denied');
            }
          });
      } else if (permissionData.permission === 'granted') {
          console.log('Safari Push Notifications permission already granted');
        // Handle subscription
      }
    }
    else{
      if (!("Notification" in window)) {
        // Check if the browser supports notifications
        alert("This browser does not support desktop notification");
      } else if (Notification.permission === "granted") {
        alert("He pedido permiso");
        // Check whether notification permissions have already been granted;
        // if so, create a notification

	Notification.requestPermission().then((permission) => {
        if (permission === 'granted') {
		      console.log(messaging);
    	    getToken( messaging,{  vapidKey: 'BDPG_nAmXAiSMdFro6ntcVsujM-rYH1rkkncZ3EnM2Ljq1dZO5xOosFGtMn6vNsTLpzZI1xLKD6czOPXJ1jCnwE' }).then((token) => {
      		console.log('Token:', token);
        // Tu lógica aquí para manejar el token
      		TokenElem.innerHTML = "token is : " + token;
    	    });
  	}
	});
	
  //      getToken().then((token) => {
    //      alert("Return token " + token);
          //const notification = new Notification("Hi there!");
      //    TokenElem.innerHTML = "token is : " + token
        //});
	

        // …
      } else if (Notification.permission !== "denied") {
        // We need to ask the user for permission
        alert("Pidiendo permiso");
	
        Notification.requestPermission().then((permission) => {
          // If the user accepts, let's create a notification
          if (permission === "granted") {
		        console.log(messaging);
            //const notification = new Notification("Hi there!");
           //getToken().then((token) => {
	         getToken( messaging, {vapidKey: 'BDPG_nAmXAiSMdFro6ntcVsujM-rYH1rkkncZ3EnM2Ljq1dZO5xOosFGtMn6vNsTLpzZI1xLKD6czOPXJ1jCnwE' }).then((token) => {
		          console.log(token);
              alert("Return token " + token);
              //const notification = new Notification("Hi there!");
              TokenElem.innerHTML = "token is : " + token
            });
          }
        });
      }

      // At last, if the user has denied notifications, and you
      // want to be respectful there is no need to bother them anymore.
    }
}*/

/*
function getToken(){
  alert("Obteniendo token");
  return messaging.getToken();
}

*/
